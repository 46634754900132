






















import { Component, Vue } from 'vue-property-decorator'
import { Toast } from 'vant'
import { getQuestionInfo, getweChatInfo } from '@/pages/videoGame/api'
import { CommonModule } from '@/store/modules/common'

import IOSUpload from './components/IOSUpload.vue'
// import QuestionList from './components/QuestionList.vue'
import { isIOS } from '@/common/utils/device/getSystemInfo'
import { callAppFunc_copyMess } from '@/common/utils/bridge'

@Component({
  name: 'HelpFeedback',
  components: {
    // QuestionList,
    IOSUpload }
})
export default class HelpFeedback extends Vue {
  textarea = ''
  isIOS = isIOS
  fileList: Array<UploadFileList> = []
  repeatClick = true
  callAppFunc_copyMess = callAppFunc_copyMess
  get userName() {
    return CommonModule.userName
  }
  wechatServiceName = ''
  weChatCode = ''
  async getweChatInfo() {
    const { data } = await getweChatInfo()
    this.wechatServiceName = data.weCharName
    this.weChatCode = data.weChatCode
    console.log('weChatCode', this.weChatCode)
  }
  goCommit() {
    this.$router.push('/help-commit')
  }
  onChange(list: Array<UploadFileList>) {
    this.fileList = list
  }
  helpList: any = []
  async init() {
    this.getweChatInfo()
    const { data } = await getQuestionInfo({ effectType: 1 })
    this.helpList = data
  }
  mounted() {
    this.init()
    // callAppFunc_setNavColor({ navColor: '#1C1330' })
    // callAppFunc_setTitleFontColor({ navFontColor: '#FFFFFF' })
  }
  beforeDestroy() {
    // callAppFunc_setNavColor({ navColor: '#FFFFFF' })
    // callAppFunc_setTitleFontColor({ navFontColor: '#1C1330' })
  }
}
